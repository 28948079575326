<template>
  <b-row
    class="match-height d-flex align-items-center"
    match-height
  >
    <b-col
      cols="12"
      md="4"
    >
      <b-form-group
        label="Mamül Kodu"
        label-for="pcode"
      >
        <b-form-input
          id="pcode"
          v-model="filterParams.pcode"
          v-b-tooltip.hover
          title="Mamül kodun tamamınız yazmanız gerekmez. Örneğin 4 hanesini yazmanız yeterlidir."
          placeholder="Mamül Kodu"
          @keydown.enter="getData"
        />
      </b-form-group>
    </b-col>
    <b-col
      cols="12"
      md="4"
    >
      <b-form-group
        label="Kalite Adı"
        label-for="id_product_qualities"
      >
        <v-select
          id="id_product_qualities"
          v-model="filterParams.id_product_qualities"
          :options="filterData.qualities"
          label="title"
          :reduce="item => item.id"
          placeholder="Seçiniz"
          @input="getData"
        />
      </b-form-group>
    </b-col>
    <b-col
      cols="12"
      md="4"
    >
      <b-form-group
        label="Gramaj"
        label-for="weight"
      >
        <b-input-group id="weight">
          <b-form-input
            v-model="filterParams.min_weight"
            placeholder="En Düşük"
            @keydown.enter="getData"
          />
          <b-input-group-append is-text>
            -
          </b-input-group-append>
          <b-form-input
            v-model="filterParams.max_weight"
            placeholder="En Yüksek"
            @keydown.enter="getData"
          />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col
      cols="12"
      md="6"
    >
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Martindale / Cycle"
            label-for="asinma"
          >
            <b-input-group id="asinma">
              <b-form-input
                v-model="filterParams.asinma_min"
                placeholder="En Düşük"
                @keydown.enter="getData"
              />
              <b-input-group-append is-text>
                -
              </b-input-group-append>
              <b-form-input
                v-model="filterParams.asinma_max"
                placeholder="En Yüksek"
                @keydown.enter="getData"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Kopuş"
            label-for="asinma_sonuc"
          >
            <v-select
              id="asinma_sonuc"
              v-model="filterParams.asinma_sonuc"
              :options="kopusData"
              :reduce="item => item.id"
              label="title"
              placeholder="Seçiniz"
              @input="getData"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-col>
    <b-col
      cols="12"
      md="6"
    >
      <b-row>
        <b-col>
          <b-form-group
            label="Pilling / Cycle"
            label-for="pilling"
          >
            <b-input-group id="pilling">
              <b-form-input
                v-model="filterParams.pilling_min"
                placeholder="En Düşük"
                @keydown.enter="getData"
              />
              <b-input-group-append is-text>
                -
              </b-input-group-append>
              <b-form-input
                v-model="filterParams.pilling_max"
                placeholder="En Yüksek"
                @keydown.enter="getData"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Pilling Değeri"
            label-for="pilling_sonuc"
          >
            <b-form-input
              id="pilling_sonuc"
              v-model="filterParams.pilling_sonuc"
              placeholder="Pilling Değeri"
              @keydown.enter="getData"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-col>
    <b-col
      cols="12"
      md="4"
    >
      <b-form-group
        label="Renk Haslığı Değeri / Kuru"
        label-for="rh_kuru"
      >
        <b-form-input
          id="rh_kuru"
          v-model="filterParams.rh_kuru"
          placeholder="Renk Haslığı Değeri / Kuru"
          @keydown.enter="getData"
        />
      </b-form-group>
    </b-col>
    <b-col
      cols="12"
      md="4"
    >
      <b-form-group
        label="Renk Haslığı Değeri / Yaş"
        label-for="rh_yas"
      >
        <b-form-input
          id="rh_yas"
          v-model="filterParams.rh_yas"
          placeholder="Renk Haslığı Değeri / Yaş"
          @keydown.enter="getData"
        />
      </b-form-group>
    </b-col>
    <b-col
      cols="12"
      md="4"
    >
      <b-form-group
        label="Hav Kaybı Başlangıç Değeri"
        label-for="hav_kaybi"
      >
        <b-form-input
          id="hav_kaybi"
          v-model="filterParams.hav_kaybi"
          placeholder="Hav Kaybı Başlangıç Değeri"
          @keydown.enter="getData"
        />
      </b-form-group>
    </b-col>
    <b-col
      cols="12"
    >
      <div class="text-center">
        <b-button
          variant="primary"
          class="mr-1"
          @click="getData"
        >
          <FeatherIcon icon="FilterIcon" />
          Filtrele
        </b-button>
      </div>
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupAppend,
  VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'FilterCard',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    getData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      kopusData: [
        { id: '1', title: 'Var' },
        { id: '0', title: 'Yok' },
      ],
    }
  },
  computed: {
    filterParams() {
      return this.$store.getters['products/filterParams']
    },
    filterData() {
      return this.$store.getters['products/filterData']
    },
  },
}
</script>
